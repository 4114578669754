<template>
  <div id="caption">

    <transition name="view">
      <div id="infomation" v-if="infomationIsOpen">
        <p class="exhibitionName">interpolation</p>
        <p class="subTitle">展示キャプション</p>
        <p>気に入った作品があれば、<br><span class="btn">⭐ Like</span>ボタンを押すと作家に直接伝わります。<br>（匿名です。個人情報などは一切送信されません）</p>
        <p>こちらは個展用のキャプションページです。<br>このページのSNSなどでのシェアはお控えください。</p>
        <button @click="closeInfomation">[ 閉じる ]</button>
      </div>
    </transition>
    <transition name="view">
      <div class="modal_overlay" v-if="infomationIsOpen" @click="closeInfomation"></div>
    </transition>

    <div id="modal_btn" @click="openModal">会場図</div>
    <transition name="view">
      <div class="modal_overlay" v-if="modalIsOpen" @click="closeModal"></div>
    </transition>
    <transition name="view">
      <div id="modal" v-if="modalIsOpen">
        <img src="@/assets/images/interpolation_caption/gallery_2.svg" alt="会場図 Gallery #1">
        <img src="@/assets/images/interpolation_caption/gallery_1.svg" alt="会場図 Gallery #2">
        <button @click="closeModal">[ 閉じる ]</button>
      </div>
    </transition>

    <p class="exhibitionName">interpolation</p>
    <p class="subTitle">展示キャプション</p>
    <!-- <div id="galleryCaption">
      <img src="@/assets/images/interpolation_caption/gallery_2.svg" alt="会場図 Gallery #1">
      <img src="@/assets/images/interpolation_caption/gallery_1.svg" alt="会場図 Gallery #2">
    </div> -->

    <!-- <p class="gallery">[ Gallery #1（西側） ]</p> -->

    <article v-for="artwork in artworks" :key="artwork.id">
      <figure>
        <img :src="require(`@/assets/images/interpolation_caption/${ artwork.imagePath }`)" :alt="artwork.name">
        <figcaption>
          <p class="name">{{ artwork.id }}.《 {{ artwork.name }} 》</p>
          <p class="caption">{{ artwork.caption }}</p>
          <button @click="postLike" v-bind:id="artwork.name" class="btn" :class="{'disabled': isLiked(artwork.name) === true}" :disabled="isLiked(artwork.name)">⭐ Like</button>
          <div class="right">{{ artwork.yearOfProduction }}{{ artwork.photographPlace ? ` | at ${ artwork.photographPlace }` : '' }}</div>
        </figcaption>
      </figure>
    </article>

    <!-- <mail></mail> -->
    <div class="twitter">
      <a href="https://twitter.com/nowhere__">
        <img src="@/assets/images/common/twitter.svg" alt="twitter">
      </a>
      <a href="https://twitter.com/nowhere__">Twitterで展示や活動情報をお知らせします。</a>
    </div>
  </div>
</template>


<script>
import axios from 'axios'
import { artworks } from '../../../entity/interpolation/artworks'
// import { toggleScrollable } from '@/utils/scroll'
import mail from '@/components/mail'

export default {
  name: 'interpolationCaption',
  components: {
    mail
  },
  data() {
    return {
      artworks,
      liked: [],
      modalIsOpen: false,
      infomationIsOpen: true,
      // isScrollable: false,
    }
  },
  mounted() {
    if (localStorage.liked) {
      const json = localStorage.getItem('liked');
      this.liked = JSON.parse(json);
    }
  },
  watch: {
    liked(newLiked) {
      const json = JSON.stringify(newLiked, undefined, 1);
      localStorage.setItem('liked', json);
    },
    // isScrollable() {
    //   console.log(this.isScrollable)
    //   toggleScrollable(this.isScrollable)
    // }
  },
  methods: {
    postLike (value) {
      this.liked.push(value.target.id)

      let params = {
        text: value.target.id
      }

      axios
        .post('https://hooks.slack.com/services/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', JSON.stringify(params), {
          withCredentials: false,
          transformRequest: [(data, headers) => {
            delete headers.post["Content-Type"]
            return data
          }]
        })
        .then()
        .catch(error => console.log(error))
    },
    isLiked (value) {
      return this.liked.includes(value)
    },
    openModal () {
      this.modalIsOpen = true
      // this.isScrollable = false
    },
    closeModal () {
      this.modalIsOpen = false
      // this.isScrollable = true
    },
    closeInfomation () {
      this.infomationIsOpen = false
      // this.isScrollable = true
    }
  }
}
</script>


<style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Baskervville&display=swap');
  @import "../../../scss/definition.scss";

  .exhibitionName {
    margin-bottom: 1rem !important;
    width: 100%;
    font-size: 2.5rem;
    line-height: 2.5rem;
    letter-spacing: .15rem;
    font-family: 'Baskervville', serif;
  }
  .subTitle {
    margin-bottom: 3rem;
    border-bottom: 1px solid;
    padding-bottom: 1rem;
  }

  #infomation {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 90vw;
    max-width: 500px;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 20%);
    border-radius: .2rem;
    background: rgba(255,255,255,1);
    padding: 2.5rem;
    transform: translate(-50%, -50%);
    z-index: 50;
    span {
      margin: .5rem;
    }
    p {
      margin-bottom: 2rem;
    }
    button {
      display: block;
      margin: 0 auto;
    }
  }
  #caption {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 3rem 0;
    .gallery {
      margin-bottom: 2rem;
      font-weight: bold;
    }
    .btn {
      display: inline-block;
      margin-top: 1rem;
      border-radius: .2rem;
      border: 1px solid #000;
      padding: 0.4rem 1rem;
      font-size: 0.9rem;
      letter-spacing: .1rem;
      &.disabled {
        border: 1px solid rgba(0,0,0,0);
        background-image: linear-gradient(to top, #c1dfc4 0%, #deecdd 100%);
      }
    }
    article {
      display: inline-block;
      margin: 0 auto 3rem;
      width: 100%;
      .name {
        margin-bottom: 1rem;
        font-size: 1.3rem;
      }
      .caption {
        margin-top: .5rem;
      }
    }
    img {
      width: 100%;
      border-radius: .25rem;
    }
    figcaption {
      margin-top: 2rem;
    }
    .right {
      margin-top: 1rem;
      text-align: right;
    }
    // #galleryCaption {
    //   display: flex;
    //   justify-content: space-between;
    //   width: 100%;
    //   padding: 3rem;
    //   img {
    //     width: 45%;
    //     border-radius: 0;
    //   }
    // }
    // @media screen and (max-width: $breakpoint_sp) {
    //   #galleryCaption {
    //     flex-wrap: wrap;
    //     flex-direction: column;
    //     img {
    //       width: 100%;
    //     }
    //   }
    // }
    .twitter {
      display: flex;
      align-items: center;
      width: 100%;
      border-top: 1px solid #EEE;
      padding: 3rem 0;
      img {
        margin-right: 1rem;
        width: 2.5rem;
      }
    }

    #modal_btn {
      display: block;
      position: fixed;
      bottom: 2rem;
      right: 2.5rem;
      box-shadow: 0px 0px 4px rgb(0 0 0 / 20%);
      border-radius: .2rem;
      background: #FFF;
      padding: 1rem 2rem;
      cursor: pointer;
    }
    .modal_overlay {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 110vw;
      height: 110vh;
      background: rgba(0,0,0,0.1);
      z-index: 9;
    }
    #modal {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      box-shadow: 0px 0px 4px rgb(0 0 0 / 20%);
      width: 85vw;
      max-width: 600px;
      border-radius: 0.25rem;
      background: #FFF;
      padding: 3rem;
      text-align: center;
      z-index: 10;
      max-height: 80vh;
      overflow: scroll;
      p:first-of-type {
        margin-bottom: 3rem;
      }
      img {
        margin-bottom: 4rem;
        max-width: 400px;
      }
      button {
        display: block;
        margin: 0 auto;
      }
    }
  }
</style>
