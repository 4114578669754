<template>
  <div id="mail">
    <p>メールアドレスをご登録いただくと、展示などの情報をお知らせします。</p>
    <div class="inner">
      <input type="email" name="email" v-model="email" placeholder="mail@mail.com" data-vv-validate-on="change" required>
      <button @click="postMailaddress">登録する</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'mail',
  data() {
    return {
      email: null
    }
  },
  methods: {
    postMailaddress () {
      console.log(this.email)
    }
  }
}
</script>


<style scoped lang="scss">

#mail {
  border-top: 1px solid #999;
  /* border-radius: 0.2rem; */
  padding: 3rem 0;
  width: 100%;
  p {
    margin-bottom: 1rem;
  }
  .inner {
    display: flex;
    justify-content: space-between;
  }
}

input {
  width: 80%;
  border: 1px #eee solid;
  border-radius: .25rem;
  padding: 1rem;
}
button {
  display: inline-block;
  border: 1px #aaa solid;
  padding: .75rem 1.5rem;
  border-radius: 0.25rem;
  &:disabled {
    color: #aaa;
  }
}
</style>
