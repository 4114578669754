import Vue from 'vue'
import App from './App.vue'
import router from './router'

// vue-mq（メディアクエリ）プラグイン
import vueMq from 'vue-mq'
Vue.use(vueMq, {
  breakpoints: {
    sp: 799,
    pc: 800
  },
  defaultBreakpoint: 'pc'
})


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
