export const artworks = [
  {
    id: 1,
    name: 'space baskerville 7pt',
    yearOfProduction: '2015',
    photographPlace: '',
    caption: 'スペース「 」だけをテキストで打ち込み、A4用紙に印刷する。一見真っ白な紙の中に、情報としての「スペース」はまったく見ることができないが、そこに存在している。「baskerville」は使用されたフォント名。',
    imagePath: '01.jpg',
  },
  {
    id: 2,
    name: 'space helvetica 6pt',
    yearOfProduction: '2022',
    photographPlace: '',
    caption: 'スペース「 」だけをテキストで打ち込み、A4用紙に印刷する。一見真っ白な紙の中に、情報としての「スペース」はまったく見ることができないが、そこに存在している。「helvetica」は使用されたフォント名。',
    imagePath: '02.jpg',
  },
  {
    id: 3,
    name: 'the back of the eyelids',
    yearOfProduction: '2022',
    photographPlace: '',
    caption: 'カメラのレンズの蓋の裏を撮影したもの。瞼の裏。',
    imagePath: '03.jpg',
  },
  {
    id: 4,
    name: '用紙のコンポジション',
    yearOfProduction: '2022',
    photographPlace: '',
    caption: '本来作品を収める額に、作品の「余白」部分を切り取り収め、構成する。「額」の外、あるいは間に、何が見えるか。',
    imagePath: '04.jpg',
  },
  {
    id: 5,
    name: 'RotateEllipseSelection() 1',
    yearOfProduction: '2022',
    photographPlace: '',
    caption: '任意の写真のランダムな座標にランダムな大きさの円を取り、ランダムな角度で回転する。スクリプトによってジェネラティブに作られた像。',
    imagePath: '05.jpg',
  },
  {
    id: 6,
    name: 'RotateEllipseSelection() 3',
    yearOfProduction: '2022',
    photographPlace: '',
    caption: '同名の作品と同じスクリプトで生成された像。',
    imagePath: '06.jpg',
  },
  {
    id: 7,
    name: 'RotateEllipseSelection() 2',
    yearOfProduction: '2022',
    photographPlace: '',
    caption: '',
    imagePath: '07.jpg',
  },
  {
    id: 8,
    name: 'quad',
    yearOfProduction: '2011',
    photographPlace: '',
    caption: 'ある部屋、または建物の「四隅（よすみ）」を撮影する、制作最初期からのシリーズ。空間の角を写し出すことで、それらを繋ぐ線、空間そのものを想起させることを試みる。',
    imagePath: '08.jpg',
  },
  {
    id: 9,
    name: 'time condensation',
    yearOfProduction: '2012',
    photographPlace: '',
    caption: '時計をスキャンしたもの。スキャンの走査線が走る間の時間の凝縮。',
    imagePath: '09.jpg',
  },
  {
    id: 10,
    name: 'RotateEllipseSelection() 4',
    yearOfProduction: '2022',
    photographPlace: '',
    caption: '',
    imagePath: '10.jpg',
  },
  {
    id: 11,
    name: 'taste',
    yearOfProduction: '2011',
    photographPlace: '',
    caption: '展示空間において「壁」は作品の支持体となるが、スプーンですくうことで「壁」自体が作品として立ち上がる。空間の味。',
    imagePath: '11.jpg',
  },
]