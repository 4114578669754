<template>
  <footer id="copyright">
    <span>Copyright {{ thisYear }} yamada mami, All Rights Reserved</span>
  </footer>
</template>

<script>
export default {
  name: 'copyright',
  data() {
    return {
      thisYear: new Date().getFullYear()
    }
  },
}
</script>


<style scoped lang="scss">

#copyright {
  font-size: .75rem;
  letter-spacing: .1rem;
}

</style>
