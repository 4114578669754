<template>
  <div id="content">
    <div id="contact">
      <ValidationObserver v-slot="ObserverProps">
        <validation-provider rules="required" v-slot="{ valid }">
          <dl>
            <dt>名前
              <span :class="{ check: true, valid: valid }">
                <span class="material-icons">done</span>
              </span>
              <p class="check-item">入力必須</p>
            </dt>
            <dd>
              <input type="text" name="name" v-model="name" placeholder="名前" required>
            </dd>
          </dl>
        </validation-provider>
        <validation-provider rules="required|email" v-slot="{ valid }">
          <dl>
            <dt>メールアドレス
              <span :class="{ check: true, valid: valid }">
                <span class="material-icons">done</span>
              </span>
              <p class="check-item">入力必須</p>
            </dt>
            <dd>
              <input type="email" name="mail" v-model="mail" placeholder="mail@mail.com" data-vv-validate-on="change" required>
            </dd>
          </dl>
        </validation-provider>
        <validation-provider rules="required" v-slot="{ valid }">
          <dl>
            <dt>内容
              <span :class="{ check: true, valid: valid }">
                <span class="material-icons">done</span>
              </span>
              <p class="check-item">入力必須</p>
            </dt>
            <dd>
              <textarea name="content" v-model="content" required></textarea>
            </dd>
          </dl>
        </validation-provider>
        <div class="submit">
          <button @click="postMesssage" :disabled="ObserverProps.invalid || !ObserverProps.validated">送信する</button>
        </div>
      </ValidationObserver>
    </div>

    <div id="modal_overlay" v-if="modalIsOpen" @click="closeModal"></div>
    <div id="modal" v-if="modalIsOpen">
      <div v-if="apiResponseSuccess">
        <p>お問い合わせを送信しました。<br>ありがとうございました。</p>
        <a href="/">[ トップページへ ]</a>
      </div>
      <div v-else>
        <p>送信に失敗しました。</p>
        <p>お手数ですが、再度送信するか<br>info@mami-y.jpへメールでご連絡下さい。</p>
        <button @click="closeModal">閉じる</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: '入力必須項目です'
});
extend('email', {
  ...email,
  message: 'メールアドレスの形式に誤りがあります'
});

export default {
  name: 'contact',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      result: [],
      name: '',
      mail: '',
      content: '',
      modalIsOpen: false,
      apiResponseSuccess: null,
    }
  },
  methods: {
    postMesssage () {
      this.postToApi();
      this.postToSlack();
    },
    postToApi () {
      let params = new URLSearchParams();
      params.append('name', this.name);
      params.append('mail', this.mail);
      params.append('content', this.content);

      axios
        .post('https://mami-y.jp/API/contact/index.php', params)
        .then(response => {
          this.result.push(response),
          this.openModal()
          this.apiResponseSuccess = true
        })
        .catch(error => {
          console.log(error)
          this.openModal()
          this.apiResponseSuccess = false
        })
    },
    postToSlack () {
      let params = {
        text: `お問い合わせがありました。\n名前：${this.name}\nメール：${this.mail}\n\n${this.content}`
      }

      axios
        .post('https://hooks.slack.com/services/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', JSON.stringify(params), {
          withCredentials: false,
          transformRequest: [(data, headers) => {
            delete headers.post["Content-Type"]
            return data
          }]
        })
        .then(response => (this.result.push(response)))
        .catch(error => console.log(error))
    },
    openModal () {
      this.modalIsOpen = true
    },
    closeModal () {
      this.modalIsOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>
  /* 変数・mixin定義 */
  @import "../scss/definition.scss";

  #contact {
    display: block;
    margin: 0 auto;
    width: 800px;
  }
  dl {
    margin-bottom: 2rem;
  }
  dt {
    margin-bottom: .75rem;
  }
  input, textarea {
    width: 100%;
    border: 1px #eee solid;
    border-radius: .25rem;
    padding: 1rem;
  }
  textarea {
    height: 20rem;
  }
  button {
    display: inline-block;
    border: 1px #aaa solid;
    padding: .75rem 1.5rem;
    border-radius: 0.25rem;
    &:disabled {
      color: #aaa;
    }
  }
  .check-item {
    display: inline-block;
    margin-left: .25rem;
    font-size: .8rem;
  }
  .submit {
    text-align: center;
  }
  .check {
    margin-left: 1rem;
    color: #eee;
    transition: .5s;
    &.valid {
      // color: #215bb1;
      color: #009688;
    }
  }


  .material-icons {
    font-size: 1.25rem;
    line-height: unset;
    vertical-align: sub;
  }


  .icon-check {
    font-size: 0.8rem;
  }

  #modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 4px rgb(0 0 0 / 20%);
    border-radius: 0.25rem;
    background: #FFF;
    padding: 4rem 5rem;
    z-index: 10;
    p:first-of-type {
      margin-bottom: 1rem;
    }
    button {
      margin-top: 2rem;
    }
  }

  #modal_overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 110vw;
    height: 110vh;
    background: rgba(0,0,0,0.1);
    z-index: 9;
  }

  // SP
  @media screen and (max-width: $breakpoint_sp) {
    #content {
      width: 100%;
    }
    #contact {
      width: 100%;
    }
    #modal {
      padding: 2rem 2.5rem;
      p {
        white-space: nowrap;
      }
    }
  }
</style>
