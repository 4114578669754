<template>
  <div id="showcase">
    <article v-for="artwork in artworks" :key="artwork.id">
      <figure>
        <img :src="require(`@/assets/images/showcase/${ artwork.imagePath }`)">
        <figcaption>
          {{ artwork.name }}<br>
          <div class="right">{{ artwork.yearOfProduction }}{{ artwork.photographPlace ? ` | at ${ artwork.photographPlace }` : '' }}</div>
        </figcaption>
      </figure>
    </article>
  </div>
</template>


<script>
import { artworks } from '../entity/artworks'

export default {
  name: 'showcase',
  data() {
    return {
      artworks
    }
  }
}
</script>


<style lang="scss">
  #showcase {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 3rem 0;
    article {
      display: inline-block;
      margin: 0 auto 3rem;
      width: 250px;
      padding: 2rem;
    }
    img {
      width: 100%;
      border-radius: .25rem;
    }
    figcaption {
      margin-top: 2rem;
      letter-spacing: .02rem;
    }
    .right {
      margin-top: 1rem;
      font-size: .8rem;
      letter-spacing: .02rem;
      text-align: right;
    }
  }
</style>
