import Vue from 'vue'
import VueRouter from 'vue-router'
import VueGtag from "vue-gtag";

// コンポーネントの読み込み
// import Home from '../views/Home.vue'
import Index from '../views/Index.vue'
import Showcase from '../views/showcase.vue'
import Profile from '../views/profile.vue'
import Exhibition from '../views/exhibition.vue'
import InterpolationCaption from '../views/exhibition/interpolation/caption.vue'
import Contact from '../views/contact.vue'

// VueRouterをプラグインとして登録
Vue.use(VueRouter)


// URLのパスと紐づくコンポーネントをマッピング
const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/showcase',
    name: 'showcase',
    component: Showcase
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  },
  {
    path: '/exhibition',
    name: 'exhibition',
    component: Exhibition
  },
  {
    path: '/exhibition/interpolation/caption',
    name: 'interpolation/caption',
    component: InterpolationCaption
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
]


// VueRouterインスタンスを作成
const router = new VueRouter({
  mode: 'history',
  routes
})


// Googleアナリティクス用設定
Vue.use(VueGtag, {
  config: {id: 'UA-223988339-1'}
}, router);


// 生成したVueRouterインスタンスをエクスポート
export default router
