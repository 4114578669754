<template>
  <div id="profile">
    <div class="inner">
      <p class="name">山田麻美</p>
      <p class="role">美術家</p>
      <p class="biography">1987 東京都生まれ<br>2012 京都芸術大学大学院修士課程修了</p>
      <p class="residence">大阪在住</p>
    </div>
    <div class="inner">
      <p class="name">yamada mami</p>
      <p class="role">artist</p>
      <p class="biography">1987 born in Tokyo<br>2012 Kyoto University of the Arts master graduation</p>
      <p class="residence">live in Osaka</p>
    </div>
  </div>
</template>


<script>
export default {
  name: 'profile'
  // data() {
  //   return {
  //     name: 'yamada mami'
  //   }
  // }
}
</script>


<style lang="scss">
  /* 変数・mixin定義 */
  @import "../scss/definition.scss";

  #profile {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    .name {
      margin-bottom: 2rem;
    }
    .role {
      margin-bottom: 3rem;
    }
    .biography {
      margin-bottom: 2rem;
    }
    .residence {
      margin-bottom: 2rem;
    }
  }

  // PC
  @media screen and (min-width: $breakpoint_pc) {
    #profile {
      width: 100%;
      .inner {
        width: 50%;
      }
    }
  }

  // SP
  @media screen and (max-width: $breakpoint_sp) {
    #profile {
      .inner {
        width: 100%;
        &:first-of-type {
          padding-bottom: 3rem;
          border-bottom: 1px solid #EEE;
        }
        &:last-of-type {
          padding-top: 3rem;
        }
      }
    }
  }

</style>
