<template>
  <nav id="header">
    <div>
      <h1><router-link to="/">yamada mami</router-link></h1>
      <router-link to="/showcase">showcase</router-link> |
      <router-link to="/profile">profile</router-link> |
      <router-link to="/exhibition">exhibition</router-link> |
      <router-link to="/contact">contact</router-link>
    </div>
    <div class="copyrightPC" v-if="$mq === 'pc'">
      <copyright></copyright>
    </div>
  </nav>
</template>

<script>
import copyright from './copyright.vue'
export default {
  name: 'headerNav',
  components: {
    copyright
  }
}
</script>


<style scoped lang="scss">
/* 変数・mixin定義 */
@import "../scss/definition.scss";

.copyrightPC {
  margin-left: auto;
}

nav {
  // color: #2c3e50;
  letter-spacing: .05rem;
}

// メイン設定 --------------------------------------------------------------------

#header {
  letter-spacing: .1rem;
}

// PC
@media screen and (min-width: $breakpoint_pc) {
  #header {
    display: flex;
    min-width: 930px;
    padding: 3rem 5rem;
    h1 {
      display: inline-block;
      margin-left: 2rem;
      margin-right: 3rem;
      letter-spacing: .1rem;
    }
  }
}

// SP
@media screen and (max-width: $breakpoint_sp) {
  #header {
    position: fixed;
    top: 0;
    width: 100vw;
    padding: 2rem 2rem;
    background-color: #FFF;
    z-index: 1;
    h1 {
      display: block;
      margin-bottom: .5rem;
    }
  }
}
// -----------------------------------------------------------------------------


</style>
