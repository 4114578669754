<template>
  <div id="app">
    <headerNav></headerNav>
    <main>
      <transition name="view" mode="out-in">
        <router-view/>
      </transition>
    </main>
    <div class="copyright_sp" v-if="$mq === 'sp'">
      <copyright></copyright>
    </div>
  </div>
</template>


<script>
import headerNav from './components/headerNav.vue'
import copyright from './components/copyright.vue'
export default {
  name: 'App',
  components: {
    headerNav,
    copyright
  }
}
</script>


<style lang="scss">

/* スタイルのリセット */
@import "./scss/destyle.scss";
/* 共通部分 */
@import "./scss/common.scss";
/* 画面遷移のトランジション */
@import "./scss/transition.scss";

.copyright_sp {
  padding: 2rem;
  text-align: center;
}

</style>
