export const artworks = [
  {
    id: 2,
    name: 'an intersection with light',
    yearOfProduction: '2011',
    photographPlace: 'gallary Monma',
    imagePath: 'image02.jpg',
  },
  {
    id: 3,
    name: 'just a moment deg C',
    yearOfProduction: '2011',
    photographPlace: '',
    imagePath: 'image03.jpg',
  },
  {
    id: 1,
    name: 'nog apple with sqweezer',
    yearOfProduction: '2012',
    photographPlace: 'printz gallary',
    imagePath: 'image01.jpg',
  },
  {
    id: 7,
    name: 'pm 2:00 / spectrope',
    yearOfProduction: '2013',
    photographPlace: 'workroom',
    imagePath: '0200pm_1.jpg',
  },
  {
    id: 5,
    name: 'from sky',
    yearOfProduction: '2013',
    photographPlace: 'KUNST ARZT',
    imagePath: 'bnR0015382c.jpg',
  },
  {
    id: 6,
    name: '50watts electrique de Paris',
    yearOfProduction: '2011',
    photographPlace: 'workroom',
    imagePath: '50watts.jpg',
  },
  {
    id: 8,
    name: 'sky',
    yearOfProduction: '2013',
    photographPlace: '',
    imagePath: 'sky.jpg',
  },
  {
    id: 13,
    name: 'the stroke of spectrum',
    yearOfProduction: '2011',
    photographPlace: 'gallary Monma',
    imagePath: 'the_stroke_of_spectre.jpg',
  },
  {
    id: 9,
    name: 'sandwatch',
    yearOfProduction: '2010',
    photographPlace: 'takumimachi gallery',
    imagePath: 'sandwatch.jpg',
  },
  {
    id: 11,
    name: '(drawing)',
    yearOfProduction: '2014',
    photographPlace: 'Q2',
    imagePath: 'temp.jpg',
  },
  {
    id: 10,
    name: '250g is 250g is not 250g',
    yearOfProduction: '2014',
    photographPlace: '',
    imagePath: '250g.jpg',
  },
  // {
  //   id: 12,
  //   name: '',
  //   yearOfProduction: '2013',
  //   photographPlace: 'KUNST ARZT',
  //   imagePath: 'set.jpg',
  // },
  {
    id: 14,
    name: 'just a moment torr',
    yearOfProduction: '2011',
    photographPlace: '',
    imagePath: 'Torr.jpg',
  },
  {
    id: 15,
    name: 'temp temp',
    yearOfProduction: '2011',
    photographPlace: 'takumimachi gallery',
    imagePath: 'temptemp.jpg',
  },
  {
    id: 16,
    name: 'image',
    yearOfProduction: '2012',
    photographPlace: 'printz gallary',
    imagePath: 'image.jpg',
  },
  {
    id: 17,
    name: 'time condensation',
    yearOfProduction: '2012',
    photographPlace: '',
    imagePath: 'time_condensation.jpg',
  },
  {
    id: 4,
    name: 'RotateEllipseSelection()',
    yearOfProduction: '2022',
    photographPlace: '',
    imagePath: 'ellipse.jpg',
  },
]